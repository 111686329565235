'use client';

import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/utils';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@radix-ui/react-icons';
import type * as React from 'react';
import { type CustomComponents, DayPicker } from 'react-day-picker';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 px-2 sm:space-y-0 sm:mx-6',
        month: 'space-y-4 ml-0',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        button_previous: cn(
          buttonVariants({ variant: 'outline' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute left-1 ml-1'
        ),
        button_next: cn(
          buttonVariants({ variant: 'outline' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute  right-1 mr-1'
        ),
        month_grid: 'w-full border-collapse space-y-1',
        weekdays: 'flex',
        weekday: 'text-muted-foreground w-8 font-normal text-[0.8rem]',
        week: 'flex w-full mt-2',
        day: cn('relative p-0 text-center text-sm focus-within:relative focus-within:z-20'),
        day_button: cn(
          buttonVariants({ variant: 'calendar' }),
          'h-8 w-8 p-0 font-normal aria-selected:opacity-100 justify-center',
          'hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
          '[&:has([aria-selected])]:hover:rounded-none'
        ),
        range_start: 'range_start rounded-l-md',
        range_end: 'range_end rounded-r-md',
        selected:
          'bg-grey-200 text-primary-foreground hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground rounded-none',
        today: 'bg-opacity-25 text-accent-foreground',
        outside:
          'outside text-muted-foreground opacity-50  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        disabled: 'text-muted-foreground opacity-50',
        range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground hover:rounded-none',
        hidden: 'invisible',
        ...classNames,
      }}
      components={{
        Chevron: CustomChevron,
      }}
      showOutsideDays={showOutsideDays}
      {...props}
    />
  );
}

const CustomChevron: CustomComponents['Chevron'] = ({
  orientation,
  disabled,
  className,
  size,
  ...props
}) => {
  const chevronStyle = cn(String(className), !!size && `size-${String(size)}`);
  switch (orientation) {
    case 'left': {
      return <ChevronLeftIcon className={chevronStyle} {...props} />;
    }
    case 'right': {
      return <ChevronRightIcon className={chevronStyle} {...props} />;
    }
    case 'up': {
      return <ChevronUpIcon className={chevronStyle} {...props} />;
    }
    case 'down': {
      return <ChevronDownIcon className={chevronStyle} {...props} />;
    }

    default: {
      return <ChevronRightIcon className="hidden size-4" />;
    }
  }
};

Calendar.displayName = 'Calendar';

export { Calendar };
