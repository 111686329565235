"use client";

import { Button, Calendar, RadixPopover } from "@/components/ui";
import { cn } from "@/utils";
import { addDays, format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { forwardRef, useEffect, useRef, useState } from "react";

const { Popover, PopoverTrigger, PopoverContent } = RadixPopover;

interface DatePickerProps {
  name: string;
  value?: Date;
  onChange?: (date: Date) => void;
  defaultValue?: Date;
}

/**
 *  DatePicker component
 *
 *  @param {string} name - The name of the date picker
 *  @param {Date} value - The value of the date picker
 *  @param {function} onChange - The onChange function of the date picker
 *  @param {Date} defaultValue - The default value of the date picker
 *  @returns {JSX.Element}
 */
const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
  ({ name, value, onChange, defaultValue = addDays(new Date(), 7) }, ref) => {
    const [date, setDate] = useState<Date>(value ?? defaultValue);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (value) {
        setDate(value);
      }
    }, [value]);

    const handleDateChange = (newDate: Date | undefined) => {
      if (!newDate) return;
      setDate(newDate);
      onChange?.(newDate);
    };

    return (
      <div>
        <Popover>
          <PopoverTrigger asChild={true}>
            <Button
              aria-label="date-picker"
              className={cn(
                "w-[280px] justify-start text-left bg-base-black font-normal",
              )}
              variant="outline"
            >
              <CalendarIcon className="mr-2 size-4" />
              {format(date, "PPP")}
            </Button>
          </PopoverTrigger>
          <input
            hidden={true}
            name={name}
            readOnly={true}
            ref={inputRef}
            type="text"
            value={date.toISOString()}
          />

          <PopoverContent className="w-auto p-0 z-50">
            <Calendar
              mode="single"
              selected={date}
              onSelect={handleDateChange}
            />
          </PopoverContent>
        </Popover>
      </div>
    );
  },
);

export { DatePicker };
DatePicker.displayName = "DatePicker";
