import { type VariantProps, cva } from 'class-variance-authority';
import { Loader2 } from 'lucide-react';
import React from 'react';

const spinnerVariants = cva('flex flex-col items-center justify-center', {
  variants: {
    show: {
      true: 'flex',
      false: 'hidden',
    },
  },
  defaultVariants: {
    show: true,
  },
});

const loaderVariants = cva('text-primary animate-spin', {
  variants: {
    size: {
      small: 'size-6',
      medium: 'size-8',
      large: 'size-12',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

type SpinnerProps = React.ComponentPropsWithoutRef<'span'> &
  VariantProps<typeof spinnerVariants> &
  VariantProps<typeof loaderVariants> & {
    label?: string;
  };

export const Spinner = React.forwardRef<HTMLSpanElement, SpinnerProps>(
  ({ size, show, className, label, ...props }, ref) => {
    return (
      <span className={spinnerVariants({ show, className })} ref={ref} {...props}>
        <Loader2 aria-hidden="true" className={loaderVariants({ size })} />
        {label && <span className="sr-only">{label}</span>}
      </span>
    );
  }
);

Spinner.displayName = 'Spinner';
